.DateRangePicker {
	width: 100%;
}

@media only screen and (min-width: 600px) {
	.DateRangePicker {
		width: 100%;
	}

	.DateInput,
	.DateInput_1 {
		max-width: 320px;
	}
}

.DayPicker_transitionContainer {
	border-radius: 10px;
}

.DayPicker_transitionContainer button {
	outline: none;
}

.DateRangePickerInput_arrow {
	display: none;
}

.DateRangePickerInput {
	border: none;
	background: none;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 50px;
}

.DayPicker_weekHeader_li {
	line-height: 0;
	margin: 0;
}

@media only screen and (max-width: 600px) {
	.DateRangePickerInput {
		grid-column-gap: 5vw;
	}
}

.DateInput,
.DateInput_1 {
	width: 100% !important;
	height: 70px;
}

.DateInput {
	border-radius: 10px;
	border: 1px solid #d6d6d6;
	display: flex;
	align-items: center;
	margin-bottom: 1.4rem;
	width: 100%;
}

.DateInput input {
	height: 68px;
	border-radius: 10px;
	border: 1px transparent;
	font-size: 1rem !important;
	font-weight: 400;
	padding: 11px 20px;
}

@media only screen and (max-width: 335px) {
	.DateRangePicker_picker {
		display: none;
	}

	.DateInput_fang {
		display: none;
	}
}
