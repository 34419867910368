.shadow {
	border-bottom: 1px solid #eae7e7;
	background: white;
	color: black;
	-webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
}

.shadow a,
.shadow h5 {
	color: black !important;
}

.make-green {
	color: #218284;
}

.active-input ~ label {
	transform: translateY(-20px);
	font-weight: 600;
	font-size: 0.8rem !important;
	color: black !important;
}

.show-search {
	display: flex !important;
}

.grey-out {
	color: #a0a0a0 !important;
}

.disappear {
	display: none;
}

.appear {
	display: inline-block !important;
}

.halt-scroll {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

body {
	margin: 0;
}

.error-border {
	border: 1px solid red !important;
}

.filepond--file-action-button.filepond--file-action-button svg {
	color: #ffffff;
}

div.filepond--progress-indicator.filepond--process-indicator svg {
	color: #ffffff;
}

.filepond--item {
	width: calc(50% - 0.5em);
	height: 150px;
}

@media screen and (max-width: 767px) {
	.filepond--item {
		width: 97%;
	}
}

.capitalize-text {
	text-transform: capitalize;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.capitalize {
	text-transform: lowercase;
}
.capitalize:first-line {
	text-transform: capitalize;
}

.range-thumb-hover {
	box-shadow: 0 0 0 10px rgba(33, 130, 132, 0.1) !important;
}

.range-thumb-active {
	box-shadow: 0 0 0 20px rgba(33, 130, 132, 0.2) !important;
}

.sticky-card {
	position: sticky !important;
	top: 120px;
	display: none;
	z-index: 2;
}

@media only screen and (min-width: 992px) {
	.sticky-card {
		display: block;
	}
}

.sticky-nav {
	position: sticky !important;
	margin-top: 40px;
	z-index: 2;
}

.infinite-scroll-component {
	overflow: unset !important;
}

.pac-container {
	border-radius: 10px;
	padding: 25px 0 20px;
	margin-top: 5px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	font-family: Poppins, sans-serif !important;
}

.pac-item {
	cursor: pointer;
	padding: 10px 40px;
	border-top: 0;
	font-size: 12px;
}

.pac-item-query {
	font-size: 16px;
	padding-right: 3px;
	color: black;
}

.pac-item-selected,
.pac-item-selected:hover {
	background-color: rgba(34, 130, 132, 0.1);
}

.pac-icon {
	background-image: none;
	position: relative;
	margin-top: 0;
	margin-right: 12px;
}

.pac-icon::after {
	font-family: 'Font Awesome 5 Free', sans-serif;
	font-weight: 900;
	content: '\f041';
	position: absolute;
	font-size: 0.85rem;
	height: 100%;
	width: 100%;
	text-rendering: auto;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	z-index: 2;
	top: 0;
}

.hdpi .pac-icon {
	background-image: none;
}

.pac-item-selected .pac-icon::after {
	color: #218284;
}

/*remove 'powered by google' from autocomplete dropdown*/
.pac-container:after {
	background-image: none !important;
	height: 0;
	padding: 0;
	margin: 0;
}

/* ----------------------------------------------
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {
	0%,
	100% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	15% {
		-webkit-transform: translateX(-15px) rotate(-6deg);
		transform: translateX(-15px) rotate(-6deg);
	}
	30% {
		-webkit-transform: translateX(12px) rotate(6deg);
		transform: translateX(12px) rotate(6deg);
	}
	45% {
		-webkit-transform: translateX(-12px) rotate(-3.6deg);
		transform: translateX(-12px) rotate(-3.6deg);
	}
	60% {
		-webkit-transform: translateX(9px) rotate(2.4deg);
		transform: translateX(9px) rotate(2.4deg);
	}
	75% {
		-webkit-transform: translateX(-6px) rotate(-1.2deg);
		transform: translateX(-6px) rotate(-1.2deg);
	}
}
@keyframes wobble-hor-bottom {
	0%,
	100% {
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	15% {
		-webkit-transform: translateX(-15px) rotate(-6deg);
		transform: translateX(-15px) rotate(-6deg);
	}
	30% {
		-webkit-transform: translateX(12px) rotate(6deg);
		transform: translateX(12px) rotate(6deg);
	}
	45% {
		-webkit-transform: translateX(-12px) rotate(-3.6deg);
		transform: translateX(-12px) rotate(-3.6deg);
	}
	60% {
		-webkit-transform: translateX(9px) rotate(2.4deg);
		transform: translateX(9px) rotate(2.4deg);
	}
	75% {
		-webkit-transform: translateX(-6px) rotate(-1.2deg);
		transform: translateX(-6px) rotate(-1.2deg);
	}
}

.wobble-hor-bottom {
	-webkit-animation: wobble-hor-bottom 0.8s infinite both;
	animation: wobble-hor-bottom 0.8s infinite both;
}

@-webkit-keyframes zoom-out {
	0% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
}
@keyframes zoom-out {
	0% {
		-webkit-transform: scale(1.5);
		transform: scale(1.5);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
}

.zoom-out {
	-webkit-animation: zoom-out 2s both ease-out;
	animation: zoom-out 2s both ease-out;
}
